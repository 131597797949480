var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PlayLayout",
    {
      staticClass: "jeopardai-categories",
      scopedSlots: _vm._u([
        {
          key: "left",
          fn: function () {
            return [
              _vm.items.length > 0
                ? [
                    _c(
                      "div",
                      { staticClass: "jeopardai-categories__list" },
                      [
                        _vm.isViewerHostLike
                          ? _c(
                              "div",
                              {
                                staticClass: "mb-3",
                                staticStyle: { "line-height": "1" },
                              },
                              [
                                _vm._v(
                                  " You can inline edit the categories below "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("JeopardAiCategoriesList"),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c("JeopardAiCategoriesForm")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }