

















import { defineComponent } from "@vue/composition-api"

import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"

import useJeopardAiCategories from "../use/useJeopardAiCategories"
import JeopardAiCategoriesList from "./JeopardAiCategoriesList.vue"
import JeopardAiCategoriesForm from "./JeopardAiCategoriesForm.vue"

export default defineComponent({
  name: "JeopardAiCategories",
  components: {
    PlayLayout,
    JeopardAiCategoriesList,
    JeopardAiCategoriesForm
  },
  setup() {
    const { items, isViewerHostLike } = useJeopardAiCategories()

    return {
      items,
      isViewerHostLike
    }
  }
})
